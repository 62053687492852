//
// forms.scss
//

// Form-control light
.form-control-light {
  background-color: $gray-100;
  border-color: $gray-100;
}

.form-control-dark {
  transition-property: background-color, border-color;
  transition-duration: 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out;
  background-color: $bg-form-dark;
  border-color: $border-form-dark;
  border-radius: $border-radius;
  color: $text-standard;
}
input.form-control-dark,
span.form-control-dark {
  height: 2.2rem;
}
.dropdown-item-container {
  max-height: 26rem;
  overflow-y: auto;
}

.form-control-dark:focus {
  background-color: $bg-form-focused-dark;
  border-color: $border-color-form-focused-dark;
  color: $color-form-focused-dark;
  outline: 0;
}

.form-control-dark:disabled,
span.disabled {
  transition-property: background-color, border-color;
  transition-duration: 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out;
  background-color: $background-color-form-control-dark-disabled !important;
  border-color: $border-color-form-control-dark-disabled !important;
  // border-color: $input-disabled-color !important;
  color: $input-disabled-color !important;
}

.search-field {
  width: 10rem;
  max-width: 30rem;
  &:focus-within {
    width: 100%;
  }

  transition: width 0.2s ease-in-out;
  .filter-close-button {
    top: 0.5rem;
    right: 1rem;
    z-index: 10;
  }
}

.form-group span {
  display: block;
  color: $text-standard;
}

.form-group label {
  margin-bottom: 0.1rem !important;
  margin-right: 10px;
}

.form-group span.error {
  display: inline-block;
  color: $text-danger;
  font-size: 0.75rem;
  font-weight: 700;
}

.form-group span.info {
  display: inline-block;
}

// Form elements (Color and Range)
input.form-control[type='color'],
input.form-control[type='range'] {
  min-height: 39px;
  border-radius: $border-radius;
}

// Custom select
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
  &:focus {
    box-shadow: none !important;
  }
}

.form-select-dark {
  background-color: $bg-select-dark;
  border: 1px solid $border-select-dark;
  border-radius: $border-radius;
  color: $text-standard;
  padding: 0.45rem 0.9rem;
  line-height: 1.25rem;
}

// .form-select {
//   min-height: 2rem;
// }

// Input
input {
  &:focus {
    outline: none;
  }
}
input::placeholder {
  color: $input-placeholder-color !important;
}

select {
  &:disabled {
    background: #363c43 !important;
    color: #68727b !important;
    border-color: #363c43 !important;
  }
}
.input-group-text {
  border-radius: 1rem;
  background-color: $bg-form-dark;
  border-color: $border-form-dark;
  color: $text-standard;
}
.form-switch {
  margin-bottom: unset !important;
  input {
    cursor: pointer;
  }
  label {
    cursor: pointer;
  }
}
